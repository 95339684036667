<template>
  <div>
    <p v-if="consumerid">{{ consumerid }}</p>
    <p v-if="sourcesystemcode">{{ sourcesystemcode }}</p>
    <p v-if="nonce">{{ nonce }}</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      consumerid: null,
      sourcesystemcode: null,
      nonce: null,
    }
  },
  mounted() {
    this.consumerid = this.$route.query.consumerid
    this.sourcesystemcode = this.$route.query.sourcesystemcode
    this.nonce = this.$route.query.nonce
  },
}
</script>
